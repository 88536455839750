

import React, { useEffect, useState } from "react";
import { Container, Row,Col } from "react-bootstrap";
import Slider from "react-slick";
import logoSlider from '../assets/images/logo-slider/footer-1.jpg';
import logoSlider1 from '../assets/images/logo-slider/footer-2.jpg';
import logoSlider2 from '../assets/images/logo-slider/footer-3.jpg';
import logoSlider3 from '../assets/images/logo-slider/footer-4.png';


import logoSlider6 from '../assets/images/logo-slider/dst-logo1.jpg';
import logoSlider7 from '../assets/images/logo-slider/jansampark.png';
import logoSlider8 from '../assets/images/logo-slider/nrsc_logo.png';
import logoSlider9 from '../assets/images/logo-slider/cmo_logo.png';
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, color: "black" }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, color: "black" }}
      onClick={onClick}
    />
  );
};

const HomeBottomSlider = () => {

  const { i18n } = useTranslation();
  const [lang, setLang] = useState(localStorage.getItem('language') === "en" ? 2 : 1);
  useEffect(() => {
    setLang(localStorage.getItem('language') === "en" ? 2 : 1);
  }, [i18n.language]);

  const images = [
    { src: logoSlider9, alt: 'logo-slider9', url: 'https://cmo.cg.gov.in/' },
    { src: logoSlider, alt: 'logo-slider1', url: 'https://www.chhattisgarhtourism.in/' },
    { src: logoSlider1, alt: 'logo-slider2', url: 'https://nad.digilocker.gov.in/' },
    { src: logoSlider2, alt: 'logo-slider3', url: 'https://digitalindiaportals.in/' },
    { src: logoSlider3, alt: 'logo-slider4', url: 'https://www.india.gov.in/' },
    { src: logoSlider6, alt: 'logo-slider6', url: 'https://dst.gov.in/' },
    { src: logoSlider7, alt: 'logo-slider7', url: 'https://dprcg.gov.in/' },
    { src: logoSlider8, alt: 'logo-slider8', url: 'https://www.nrsc.gov.in/' }

  ];


  const handleClick = (url) => {
    Swal.fire({
      html: lang === 2 ? `
                This link shall take you to a page/ website outside this website. `
        : ` यह लिंक आपको इस वेबसाइट के बाहर एक पेज/वेबसाइट पर ले जाएगा।`,
      icon: "question",
      showConfirmButton: true,
      showDenyButton: true,
       confirmButtonText: lang === 2 ? "OK" : "हाँ",
      denyButtonText: lang === 2 ? "Cancel" : "नहीं"
    }).then((result) => {
      if (result.isConfirmed) {
        window.open(url);
      }
    });
  };


  var settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="bottomSliderbg">
    <Container fluid="xxl" className="bottomSlider">
      <Row>
        <Col className="backgroundColor">
        <Slider className="logoSlider" {...settings}>
          {images.map((image, index) => (
            <div className="p-1 pb-2 d-flex justify-content-center slider-pop" key={index}>
              <Tooltip placement="bottom" title={image.url} >
                <a target="_blank" rel="noopener noreferrer"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault(); // Prevents the default link behavior
                    handleClick(image.url);
                  }}

                >
                  <img src={image.src} className="img-fluid embossShadow p-2" alt={image.alt} />
                </a>
              </Tooltip>
            </div>
          ))}


        </Slider>
        </Col>
      </Row>
      </Container>
    </div>
  );
};

export default HomeBottomSlider;
